import "./Card.css";
import { Link } from "react-router-dom";
import Truncate from "react-truncate";
import { NavLink } from "react-bootstrap";
import { useEffect, useState } from "react";

const Card = (props) => {
  const [isMobile, setIsMobile] = useState(
    window.document.documentElement.clientWidth <= 768
  );

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  const resizeHandler = () => {
    setIsMobile(window.document.documentElement.clientWidth <= 768);
  };

  const d = new Date(props.date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  //Latest News on the whatsnew page
  return props.pageType === "whatsNew" ? (
    <NavLink
      target={props.media ? "_blank" : ""}
      href={props.media || props.link}
      rel="noopener noreferrer"
      className="media-tab m-0"
    >
      <div className={`card whatsNewCard`}>
        {props.image.length > 0 ? (
          <img src={props.image} className="card-img-top" alt={props.title} />
        ) : (
          <img
            src="../images/swifty-placeholder.png"
            className="card-img-top"
            alt="Swifty Placeholder Image"
          />
        )}
        <div className="card-body">
          <div className="card-text">
            <p className="news-date">{d}</p>
            <p className={"card-title"}>{props.title}</p>
            {props.text ? <p className="textContent">{props.text}</p> : ""}
            {isMobile ? (
              ""
            ) : (
              <p className="news-short-desc">
                <Truncate lines={3} ellipsis={<span> ...</span>}>
                  {props.short_description}
                </Truncate>
              </p>
            )}
          </div>
        </div>
      </div>
    </NavLink>
  ) : (
    // Latest News on the homepage carousel
    <Link
      to={props.link || "/article"}
      target={props.media ? "_blank" : ""}
      rel="noopener noreferrer"
    >
      <div className="card card-carousel">
        {props.image.length > 0 ? (
          <img
            src={props.image}
            className="card-img-top home-img-top"
            alt={props.title}
          />
        ) : (
          <img
            src="../images/swifty-placeholder.png"
            className="card-img-top home-img-top"
            alt="Swifty Placeholder Image"
          />
        )}
        {/* <img src={props.image} className="card-img-top" /> */}
        <div className="card-body">
          <div className="card-text">
            <p className="news-date">{d}</p>
            <p className="news-desc">{props.title}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;

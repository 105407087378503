export const BackIcon = (props) => {
  const { className, fill = "#fff" } = props;
  return (
    <svg
      alt="Back"
      className={className}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.70711 0.292893C8.09763 0.683418 8.09763 1.31658 7.70711 1.70711L3.41421 6H15C15.5523 6 16 6.44771 16 7C16 7.55228 15.5523 8 15 8H3.41421L7.70711 12.2929C8.09763 12.6834 8.09763 13.3166 7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071L0.292893 7.70711C0.105357 7.51957 0 7.26522 0 7C0 6.73478 0.105357 6.48043 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976311 7.31658 -0.0976311 7.70711 0.292893Z"
        fill={fill}
      />
    </svg>
  );
};

export const NextIcon = (props) => {
  const { className, fill = "#fff" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M1.16667 7.00008H12.8333M12.8333 7.00008L7 1.16675M12.8333 7.00008L7 12.8334"
        stroke={fill}
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

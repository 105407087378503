import React from "react";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import CookieComponent from "../components/CookieComponent/CookieComponent";
import Header from "../components/Header/Header";
import "../components/Styles/WhoWeAre.css";

const WhoWeAre = () => {
  return (
    <>
      <Header />
      <div className="container-xxl who-we-are">
        <div className="container">
          <Helmet>
            <title>Meet Our Team | Swifty Global</title>
            <meta
              name="description"
              content="Discover the people behind Swifty Global. Explore our robust team of 40+ professionals, spanning design, development, trading, operations, customer service, and compliance."
              data-react-helmet="true"
            />
            <meta
              name="keywords"
              content="Swifty Global, Executive Team, Trading Experts, Operations Team, Customer Service, Compliance, Industry Leaders"
              data-react-helmet="true"
            />
            <meta
              property="og:title"
              content="Meet Our Team | Swifty Global"
              data-react-helmet="true"
            />
            <meta
              property="og:description"
              content="Discover the people behind Swifty Global. Explore our robust team of 40+ professionals, spanning design, development, trading, operations, customer service, and compliance."
              data-react-helmet="true"
            />
            <meta
              property="og:type"
              content="website"
              data-react-helmet="true"
            />
            <meta
              property="og:url"
              content="https://www.swifty.global/whoweare"
              data-react-helmet="true"
            />
          </Helmet>
          <div className="row">
            <div className="col-md-6 who-we-are-banner">
              <h3 className="banner-title-who">Who We Are</h3>
              <p className="banner-text-who">
                Meet the executive team at Swifty Global. The team has <br />
                been growing and we now have a strong team of 40+ <br />
                people that consist of designers, developers, traders, <br />
                operations, customer service and compliance.
              </p>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img
                  src="../images/jamesgibbons.png"
                  alt="James Gibbons - CEO"
                  className="ceo-chairman-img"
                />
                <p className="name-title">James Gibbons - CEO</p>
                <a
                  href="https://www.linkedin.com/in/james-gibbons-uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="../images/linkedin.svg"
                    alt="James Gibbons - LinkedIn"
                    className="linkedin-icon"
                  />
                </a>
              </div>
              <div className="col-md-6">
                <img
                  src="../images/nicklink.png"
                  alt="Nick Link - Chairman"
                  className="ceo-chairman-img"
                />
                <p className="name-title">Nick Link - Chairman</p>
                <a
                  href="https://www.linkedin.com/in/nicolas-nick-link-74978aa/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="../images/linkedin.svg"
                    alt="Nick Link - LinkedIn"
                    className="linkedin-icon"
                  />
                </a>
              </div>
              <p className="swifty-history-text">
                We are Swifty Global, a global company lead by James Gibbons
                (CEO) and Nicolas Link (Chairman) and supported by our
                ever-growing team, our goal is to revolutionise the betting
                sector. <br />
                <br />
                CEO James is the brains of the operation. He has over 15 years’
                experience working in solutioning, strategy, design and
                programme management. At just 23, he created a mobile voucher
                app compatible with Apple, Android and Blackberry devices,
                eventually selling it to a US-based company. Just before
                establishing Swifty Global, James headed up the Digital Visitor
                Experience team at one of the largest events in the world, Expo
                2020 Dubai. <br /> <br />
                Chairman Nicolas is a serial entrepreneur who knows how to spot
                a business with potential. As the CEO of ILUS International, a
                global investment company, Nicolas is using his money-savvy mind
                to assist James in assembling a stellar team of Directors to
                steer Swifty skyward.
              </p>
            </div>
          </div>
          <Footer />
          <CookieComponent />
        </div>
      </div>
    </>
  );
};
export default WhoWeAre;

import React, { useEffect, useState } from "react";
import "../Styles/LatestNews.css";
import LatestNewsMobile from "./LatestNewsMobile";
import Card from "../Card/Card";
import LatestNewsMobileFilter from "./LatestNewsMobileFilter";
import { Link } from "react-router-dom";

const LatestNews = ({ page, types, cards = [], latestNews = [] }) => {
  const [isMobile, setIsMobile] = useState(
    window.document.documentElement.clientWidth <= 991
  );
  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  const resizeHandler = () => {
    setIsMobile(window.document.documentElement.clientWidth <= 991);
  };

  return (
    <>
      {latestNews.length > 0 &&
        (isMobile && page === "home" ? (
          <LatestNewsMobile page={"home"} types={types} cards={latestNews} />
        ) : (
          <>
            <div className="news-card-text">
              <p>Latest News</p>

              {page !== "whatsNew" && page !== "article" ? (
                <Link to={"/whatsnew"}>
                  <h6 className="link-light">View All</h6>
                </Link>
              ) : (
                ""
              )}
            </div>

            <div className="row m-0">
              {latestNews.map((card, index) => {
                return (
                  <div
                    key={index}
                    className={` ${
                      page === "whatsNew" ? "col-md-6" : "col-md-4"
                    } col-12`}
                  >
                    <Card
                      link={`/article/${card.slug}`}
                      pageType={page}
                      image={card.image}
                      title={card.title}
                      date={card.date}
                      text={card.text}
                      short_description={card.short_description}
                      media={card.media}
                      className={"home-card"}
                    />
                  </div>
                );
              })}
            </div>
          </>
        ))}
      {cards.length > 0 && (
        <>
          <div className="news-card-text">
            {page == "article" ? <p>Latest News</p> : <p>All News</p>}

            {page !== "whatsNew" && page !== "article" ? (
              <Link to={"/whatsnew"}>
                <h6 className="link-light">View All</h6>
              </Link>
            ) : (
              ""
            )}
          </div>
          <div className="row m-0">
            {cards.map((card, index) => {
              return (
                <div key={index} className="col-md-4 col-12">
                  <Card
                    link={`/article/${card.slug}`}
                    pageType={page}
                    image={card.image}
                    title={card.title}
                    date={card.date}
                    text={card.text}
                    short_description={card.short_description}
                    media={card.media}
                    className={"home-card"}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};
export default LatestNews;

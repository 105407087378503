import React, { useEffect, useState, lazy } from "react";
import LatestNews from "../components/LatestNews/LatestNews";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import axios from "axios";
import HomeSlider from "../components/HomeSlider/HomeSlider";
import CookieComponent from "../components/CookieComponent/CookieComponent";
import Header from "../components/Header/Header";
import "../components/Styles/Home.css";

const Home = () => {
  const [isMobile, setIsMobile] = useState(
    window.document.documentElement.clientWidth <= 991
  );

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  const resizeHandler = () => {
    setIsMobile(window.document.documentElement.clientWidth <= 991);
  };

  const [types, setTypes] = useState("all");
  const [cards, setCards] = useState([]);

  const getDocs = (limit) => {
    axios
      .post(
        "https://18z0qwmdu8.execute-api.eu-west-1.amazonaws.com/main/v1/data",
        {
          collection: "articles",
          sort: { date: -1 },
          limit: limit ? limit : 3,
        }
      )
      .then((res) => {
        setCards(res.data.documents);
      });
  };
  useEffect(() => {
    getDocs();
  }, []);

  return (
    <>
      <Header />
      <div className="container-xxl home">
        <div className="container"></div>
        <HomeSlider />
        <div className="container">
          <Helmet>
            <title>Swifty Global | Home</title>
          </Helmet>
          <LatestNews page={"home"} types={types} latestNews={cards} />
          <div className="container" id="who-we-are-content">
            <div className="row">
              <div className="col-md-6">
                <img
                  src="../images/person-with-laptop.png"
                  alt="Young entrepreneur with laptop"
                  className="who-we-are-img"
                />
              </div>
              <div className="col-md-6">
                <h2 className="who-we-are-title">Who we are</h2>
                <span className="who-we-are-text">
                  <p>
                    Over the past four years, our team at Swifty Global has been
                    developing and perfecting two innovative platforms that aim
                    to take on the gambling sector.
                    <br />
                    <br />
                    Swifty Sports is our traditional sportsbook and casino
                    offering both pre-match and in-play bets. Swifty Sports has
                    over 1000+ slots, table games and more.
                    <br />
                    <br />
                    Swifty Predictions is a fun new interactive way to bet: take
                    your pick from the hundreds of in-game micro-predictions,
                    and swipe on the ones you want to bet on!
                  </p>
                </span>
              </div>
            </div>
          </div>

          {/* <div className="container" id="highlights">
          <div className="row">
            {isMobile ? (
              <>
                <HighlightsMobile />
              </>
            ) : (
              <>
                <div className="col-md-3">
                  <div className="highlights-img">
                    <img src="../images/q1.svg" alt="Q1" />
                  </div>
                  <span>
                    <ul className="highlights-list">
                      <li>UK Gambling License</li>
                      <li>Launch Swifty Wallet</li>
                    </ul>
                  </span>
                </div>
                <div className="col-md-3">
                  <div className="highlights-img">
                    <img src="../images/q2.svg" alt="Q2" />
                  </div>
                  <span>
                    <ul className="highlights-list">
                      <li>Launch Swifty Predictions</li>
                      <li>Launch Swifty Sportsbook</li>
                    </ul>
                  </span>
                </div>
                <div className="col-md-3">
                  <div className="highlights-img">
                    <img src="../images/q3.svg" alt="Q3" />
                  </div>
                  <span>
                    <ul className="highlights-list">
                      <li>Marketing Campaign</li>
                      <li>Launch Swifty Casino</li>
                      <li>Additional Gambling Licenses (TBC)</li>
                    </ul>
                  </span>
                </div>
                <div className="col-md-3">
                  <div className="highlights-img">
                    <img src="../images/q4.svg" alt="Q4" />
                  </div>
                  <span>
                    <ul className="highlights-list">
                      <li>Financial license for issuing accounts</li>
                      <li>Phase 2 Swifty Wallet</li>
                    </ul>
                  </span>
                </div>
              </>
            )}
          </div>
        </div> */}
          <div className="container" id="swifty-predictions-content">
            <div className="row" id="swift-predictions">
              <div className="col-md-6">
                <h2 className="swifty-predictions-title">Swifty Predictions</h2>
                <span className="swifty-predictions-text">
                  <p>
                    Introducing Swifty Predictions, the game you play whilst
                    watching the game. <br /> <br />
                    Convinced that the Yankees will pull it out of the bag
                    before the game begins? Confident that there will be a
                    three-pointer in the next ten minutes? Positive that Federer
                    will win the next set? <br /> <br />
                    Swifty Predictions allows you to bet on multiple outcomes
                    whilst watching your favourite sport. Swipe right to play
                    and left to skip on multiple in-game micro-predictions; how
                    many bets you make is up to you. <br /> <br />
                    <a
                      href="https://www.swiftypredictions.co.uk/"
                      target="_blank"
                    >
                      www.swiftypredictions.co.uk
                    </a>
                  </p>
                </span>
              </div>
              <div className="col-md-6">
                <img
                  src="../images/sports.png"
                  alt="Sports"
                  className="swifty-predictions-img"
                />
              </div>
            </div>
          </div>
          <div className="container" id="swifty-predictions-content">
            <div className="row" id="swift-predictions">
              <div className="col-md-6">
                <img
                  src="../images/swifty-casino.png"
                  alt="Swifty Casino"
                  className="swifty-predictions-img"
                />
              </div>
              <div className="col-md-6">
                <h2 className="swifty-predictions-title">
                  Swifty Sports & Casino
                </h2>{" "}
                <br />
                <span className="swifty-predictions-text">
                  <p>
                    Swifty Sports & Casino is an online gambling company. <br />{" "}
                    <br />
                    Swifty Sports allows you to browse and place both pre-match
                    and in-play bets on all your favourite sports. Bet on the
                    horses, football, baseball, boxing and more. <br /> <br />
                    <a href="https://www.swiftysports.com/" target="_blank">
                      www.swiftysports.com
                    </a>
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div className="container" id="who-we-are-content">
            <div className="row">
              <div className="col-md-6">
                <h2 className="who-we-are-title">
                  Your Sportsbook and Igaming Platform
                </h2>
                <span className="who-we-are-text" id="swifty-wallet-text">
                  <p>
                    Our Platform offers a global betting platform that provides
                    a service for both established operators and new entrants
                    who are looking to disrupt the industry.
                    <br />
                    <br />
                    Our sportsbook offers a comprehensive solution for pre-match
                    and live betting across a wide array of sports markets.
                    <br />
                    <br />
                    We offer a wide range of games, from slots to table games,
                    ensure a diverse and engaging gaming experience.
                    <br />
                    <br />
                    <a
                      href="https://www.swiftytechnologies.com/"
                      target="_blank"
                    >
                      www.swiftytechnologies.com
                    </a>
                  </p>
                </span>
              </div>
              <div className="col-md-6">
                <img
                  src="../images/swifty-wallet.png"
                  alt="Swifty Platform"
                  className="who-we-are-img"
                />
              </div>
            </div>
          </div>
          <Footer />
          <CookieComponent />
        </div>
      </div>
    </>
  );
};
export default Home;

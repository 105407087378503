import React from "react";
import "../Styles/LatestNews.css";
import Slider from "react-slick";
import Card from "../Card/Card";

const LatestNewsMobile = ({ page, cards }) => {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 481,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className="container">
      <div className="row m-0 mt-2">
        <div className="news-card-text">
          <h3>Latest News</h3>
        </div>
        <Slider {...settings}>
          {cards.map((card, index) => {
            return (
              <div key={index} className="col-md-4 newsRow">
                <Card
                  link={`/article/${card.slug}`}
                  pageType={page}
                  image={card.image}
                  title={card.title}
                  date={card.date}
                  text={card.text}
                  short_description={card.short_description}
                  media={card.media}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};
export default LatestNewsMobile;

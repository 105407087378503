import React, { useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import LatestNews from "../components/LatestNews/LatestNews";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axios from "axios";
import CookieComponent from "../components/CookieComponent/CookieComponent";
import Pagination from "../components/Pagination/pagination";
import "../components/Styles/WhatsNew.css";
import Header from "../components/Header/Header";

const WhatsNew = () => {
  const [isMobile, setIsMobile] = useState(
    window.document.documentElement.clientWidth <= 991
  );
  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  const resizeHandler = () => {
    setIsMobile(window.document.documentElement.clientWidth <= 991);
  };

  const [cards, setCards] = useState([]);
  const [singleCard, setSingleCard] = useState([]);
  const [latestNews, setLatestNews] = useState([]);
  const [pageCount, setPageCount] = useState(3);
  const [isLoading, setIsLoading] = useState(false);
  const [check, setCheck] = useState(true);

  const loadMore = (e) => {
    const selectedPage = e.selected;
    setIsLoading(true);

    let skip = 0;
    if (selectedPage === 1) skip = 12;
    else skip = 9 * selectedPage + 3;
    getDocs(9, skip);
  };

  const getDocs = (limit, skip) => {
    const url =
      "https://18z0qwmdu8.execute-api.eu-west-1.amazonaws.com/main/v1/data";
    const payload = {
      collection: "articles",
      sort: { date: -1 },
      skip: skip ? skip : 0,
      limit: limit ? limit : 12,
    };
    axios.post(url, payload).then((res) => {
      if (check === true) {
        let resp = res.data.documents;
        setSingleCard(resp[0]); //first object
        const latestNews = resp.slice(1, 3);
        setLatestNews(latestNews);
        resp = resp.slice(3);
        setCards(resp); //second object onwards
        setCheck(false);
        setIsLoading(false);
      } else {
        let resp = res.data.documents;
        if (skip === 3) resp = resp.slice(3);
        setCards(resp);
        setIsLoading(false);
      }
    });
  };

  const getPageCount = () => {
    const url =
      "https://18z0qwmdu8.execute-api.eu-west-1.amazonaws.com/main/v1/data";
    const payload = { collection: "articles", sort: { date: -1 } };

    axios.post(url, payload).then((res) => {
      let resp = res.data.documents;
      resp = resp.slice(3);
      setPageCount(Math.ceil(resp.length / 9));
    });
  };

  useEffect(() => {
    if (!cards.length) getDocs();
  }, []);

  useEffect(() => {
    if (!latestNews.length) getPageCount();
  }, []);

  return (
    <>
      <Header />
      <div className="container-xxl whats-new">
        <div className="whats-new-hero">
          <div className="container">
            <Helmet>
              <title>Latest News and Updates | Swifty Global</title>
              <meta
                name="description"
                content="Explore the latest Swifty Global news, press releases, and important announcements relating to the company. Where we have come from and where we are going."
                data-react-helmet="true"
              />
              <meta
                name="keywords"
                content="Swifty Global News, Technology Updates, Latest Trading News, Swifty Global Announcements, Trading Platform Developments, Swifty Press Releases, Market Insights, Gambling Trends"
                data-react-helmet="true"
              />
              <meta
                property="og:title"
                content="Latest News and Updates | Swifty Global"
                data-react-helmet="true"
              />
              <meta
                property="og:description"
                content="Explore the latest Swifty Global news, press releases, and important announcements relating to the company. Where we have come from and where we are going."
                data-react-helmet="true"
              />
              <meta
                property="og:type"
                content="website"
                data-react-helmet="true"
              />
              <meta
                property="og:url"
                content="https://www.swifty.global/whatsnew"
                data-react-helmet="true"
              />
            </Helmet>

            <div className="row">
              <div className="col-md-6">
                <span className="banner-title">
                  <p>{singleCard.title}</p>
                </span>
                <span className="banner-txt">
                  <p>{singleCard.short_description}</p>
                </span>
                {singleCard.title ? (
                  <div className="read-button-gradient">
                    <Link to={`/article/${singleCard.slug}`}>
                      <button className="read-button">Read More</button>
                    </Link>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="latest-news-wn-page p-0">
                <LatestNews
                  page={"whatsNew"}
                  types={"all"}
                  cards={cards}
                  latestNews={latestNews}
                />

                <Pagination onPageChange={loadMore} pageCount={pageCount} />
              </div>
            </div>
            <Footer />
            <CookieComponent />
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatsNew;

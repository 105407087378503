import React from "react";
import Drcr from "../Drcr/Drcr";
import NavbarComponent from "../Navbar/Navbar";

const Header = () => {
  return (
    <div className="sticky-top">
      <Drcr />
      <NavbarComponent />
    </div>
  );
};

export default Header;

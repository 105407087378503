import ReactPaginate from "react-paginate";
import { BackIcon, NextIcon } from "../../utils/icons";
import { useEffect, useState } from "react";

import "./pagination.css";

const Pagination = ({ onPageChange, pageCount }) => {
  const [isMobile, setIsMobile] = useState(
    window.document.documentElement.clientWidth <= 991
  );

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  const resizeHandler = () => {
    setIsMobile(window.document.documentElement.clientWidth <= 991);
  };

  const handlePageChange = (e) => {
    const element = document.querySelector(".news-card-text");

    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: elementPosition, behavior: "smooth" });
      onPageChange(e);
    }
  };

  return (
    <ReactPaginate
      containerClassName="pagination-container pagination"
      pageClassName={"pagination-item"}
      activeClassName={"pagination-active"}
      breakLabel="..."
      nextLabel={
        <div className="icons">
          Next <NextIcon fill="#94969c" />
        </div>
      }
      onPageChange={handlePageChange}
      pageRangeDisplayed={isMobile ? 1 : 3}
      marginPagesDisplayed={isMobile ? 1 : 3}
      pageCount={pageCount}
      previousLabel={
        <div className="icons">
          <BackIcon fill="#94969c" /> Previous
        </div>
      }
      hrefAllControls={"#"}
    />
  );
};

export default Pagination;

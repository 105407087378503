import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import CookieComponent from "../components/CookieComponent/CookieComponent";
import Header from "../components/Header/Header";
import "../components/Styles/CookieTermsPrivacy.css";

const CookiePolicy = () => {
  useEffect(() => {
    (function (d, s, id) {
      var js,
        tjs = d.getElementsByTagName(s)[0];
      // if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://app.termly.io/embed-policy.min.js";
      tjs.parentNode.insertBefore(js, tjs);
    })(document, "script", "termly-jssdk");
  }, []);
  return (
    <>
      <Header />
      <div className="container-xxl">
        <div className="container">
          <React.Fragment>
            <Helmet>
              <title>Cookie Policy | Swifty Global</title>
              <meta
                name="description"
                content="Understand how we use cookies on the Swifty Global's website. Read our Cookie Policy to learn about the data we collect for enhancing user experiences and providing tailored content."
                data-react-helmet="true"
              />
              <meta
                property="og:title"
                content="Cookie Policy | Swifty Global"
                data-react-helmet="true"
              />
              <meta
                property="og:description"
                content="Understand how we use cookies on the Swifty Global's website. Read our Cookie Policy to learn about the data we collect for enhancing user experiences and providing tailored content."
                data-react-helmet="true"
              />
              <meta
                property="og:type"
                content="website"
                data-react-helmet="true"
              />
              <meta
                property="og:url"
                content="https://www.swifty.global/cookiepolicy"
                data-react-helmet="true"
              />
            </Helmet>
            <div className="cookie-policy-container">
              <div className="center-container">
                <div
                  name="termly-embed"
                  data-id="d03e6126-c743-41a7-93e5-369f53468a44"
                  data-type="iframe"
                ></div>
              </div>
            </div>
          </React.Fragment>
          <Footer />
          <CookieComponent />
        </div>
      </div>
    </>
  );
};

export default CookiePolicy;

import "./App.css";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavbarComponent from "./components/Navbar/Navbar";
import LatestNews from "./components/LatestNews/LatestNews";
import Footer from "./components/Footer/Footer";
import ScrollOnTop from "./components/ScrollOnTop/ScrollOnTop";

function App() {
  return (
    <div className="container">
      <NavbarComponent />
      <LatestNews />
      <Footer />
    </div>
  );
}

export default App;
